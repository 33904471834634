import React from "react";

// ============ Auth ============
export const ResetPassword = React.lazy(() => import("pages/ResetPassword"));
export const PasswordEditForm = React.lazy(() =>
  import("pages/PasswordEditForm")
);
export const VerificationCodeForm = React.lazy(() =>
  import("pages/VerificationCodeForm")
);
// ============ Auth ============

export const AdminRouting = React.lazy(() =>
  import("modules/admin/AdminRouting")
);
export const SettingRouting = React.lazy(() =>
  import("modules/setting/SettingRouting")
);
export const UserPlanRouting = React.lazy(() =>
  import("modules/userPlan/UserPlanRouting")
);
export const UserRouting = React.lazy(() => import("modules/user/UserRouting"));
export const BannersRouting = React.lazy(() =>
  import("modules/banner/BannersRouting")
);
export const InvetationRouting = React.lazy(() =>
  import("modules/invitation/InvetationRouting")
);
export const ProInvetationRouting = React.lazy(() =>
  import("modules/pro-invitaion/ProInvetationRouting")
);
export const CategoryRouting = React.lazy(() =>
  import("modules/category/CategoryRouting")
);
export const StatisticRouting = React.lazy(() =>
  import("modules/statistic/StatisticRouting")
);
export const StorePlanRouting = React.lazy(() =>
  import("modules/storePlan/StorePlanRouting")
);
export const StoreRouting = React.lazy(() =>
  import("modules/store/StoreRouting")
);
export const PostRouting = React.lazy(() => import("modules/post/PostRouting"));
export const ReportRouting = React.lazy(() =>
  import("modules/report/ReportRouting")
);
export const NotificationRouting = React.lazy(() =>
  import("modules/notification/NotificationRouting")
);
export const RateRouting = React.lazy(() => import("modules/rate/RateRouting"));
export const PaymentRouting = React.lazy(() =>
  import("modules/payment/PaymentRouting")
);
export const UsernameReservationRouting = React.lazy(() =>
  import("modules/usernameReservation/UsernameReservationRouting")
);
export const CoinTransactionRouting = React.lazy(() =>
  import("modules/coin_transaction/CoinTransactionRouting")
);
export const LeftUpSettingRouting = React.lazy(() =>
  import("modules/leftup-setting/LeftUpSettingRouting")
);
export const AccountRouting = React.lazy(() =>
  import("../modules/account/AccountRouting")
);
export const PostPlanRouting = React.lazy(() =>
  import("../modules/post-plan/PostPlanRouting")
);
export const BoostPostRouting = React.lazy(() =>
  import("../modules/boost-post/BoostPostRouting")
);
export const ShadowUserRouting = React.lazy(() =>
  import("../modules/shadow-user/ShadowUserRouting")
);
export const ShadowPostRouting = React.lazy(() =>
  import("../modules/shadow-post/ShadowPostRouting")
);
export const GroupPlanRouting = React.lazy(() =>
  import("../modules/group/GroupPlanRouting")
);
export const VoucherPlanRouting = React.lazy(() =>
  import("../modules/voucher-plan/VoucherPlanRouting")
);
export const VoucherRouting = React.lazy(() =>
  import("../modules/voucher/VoucherRouting")
);

// ============ DashboardRouting ============
export const DashboardRouting = React.lazy(() =>
  import("../modules/dashboard/DashboardRouting")
);
// ============ DashboardRouting ============
